import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '~/theme';
import { DrawerNotificationsProvider } from '~/state/DrawerNotificationsProvider';
import { PopUpDisclaimerProvider } from '~/state/PopUpDisclaimerProvider';
import { HelmetProvider } from 'react-helmet-async';
import { KeycloakProvider } from '~/state/KeycloakProvider';
import { KycRouter } from '~/pages/KycRouter';
import { LocalisationProvider } from '~/state/LocalisationProvider';
import { CustomProductFruits } from './state/CustomProductFruits';
import { UiContextProvider } from '~/state/UiContextProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '~/api/queryClient';

export default function KycApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UiContextProvider>
            <LocalisationProvider>
              <KeycloakProvider>
                <CustomProductFruits />
                <PopUpDisclaimerProvider>
                  <DrawerNotificationsProvider>
                    <KycRouter />
                  </DrawerNotificationsProvider>
                </PopUpDisclaimerProvider>
              </KeycloakProvider>
            </LocalisationProvider>
          </UiContextProvider>
        </ThemeProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}
