import { FRAME_Z_INDEX } from '@ev/eva-container-api';
import { styled } from '@mui/system';
import { theme } from '~/theme';

export const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: `[left] ${theme.spacing(10)} [content] 1fr [right]`,
  gridTemplateRows: `[top] 68px [content] 1fr [bottom]`,
  minHeight: '100vh',
});

export const Aside = styled('aside')({
  backgroundColor: theme.palette.backgroundExtension.white,
  boxShadow: `0px 2px 4px ${theme.palette.grey[400]}`,
  gridRowEnd: 'bottom',
  gridRowStart: 'top',

  zIndex: FRAME_Z_INDEX,
});

export const Header = styled('header', {
  shouldForwardProp: (prop) => prop !== 'hasOffset',
})<{
  hasOffset: boolean;
}>((props) => ({
  backgroundColor: theme.palette.backgroundExtension.white,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  gridColumnEnd: 'right',
  gridColumnStart: props.hasOffset ? 'content' : 'left',
  maxHeight: 68,
  zIndex: FRAME_Z_INDEX,
}));

export const Main = styled('main', {
  shouldForwardProp: (prop) => !(prop === 'hasAside' || prop === 'hasHeader'),
})<{
  hasAside: boolean;
  hasHeader: boolean;
}>(({ hasAside, hasHeader }) => ({
  gridColumnEnd: 'right',
  gridColumnStart: hasAside ? 'content' : 'left',
  gridRowEnd: 'bottom',
  gridRowStart: hasHeader ? 'content' : 'top',
  position: 'relative',
}));
