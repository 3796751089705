import React, { useEffect, useRef, useState } from 'react';
import { setupI18n } from '~/util/i18next';

export function LocalisationProvider({ children }: React.PropsWithChildren) {
  const [isI18nReady, setIsI18nReady] = useState(false);
  const setupCalledRef = useRef(false);

  useEffect(() => {
    if (!setupCalledRef.current) {
      setupCalledRef.current = true;
      setupI18n().then(() => setIsI18nReady(true));
    }
  }, []);

  if (!isI18nReady) {
    return null;
  }
  return <>{children}</>;
}
