import { useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useLocale, useJwtToken } from '@ev/eva-container-api';

export const CustomProductFruits = () => {
  const { jwtToken } = useJwtToken();
  const username = jwtToken?.preferred_username;
  const email = jwtToken?.email;
  const firstname = jwtToken?.given_name;
  const lastname = jwtToken?.family_name;
  const userInfo = useMemo(() => {
    return {
      username,
      email,
      firstname,
      lastname,
    };
  }, [username, email, firstname, lastname]);

  const language = useLocale();

  return (
    <>
      {userInfo && language ? (
        <ProductFruits
          workspaceCode={import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_ID}
          language={language}
          user={userInfo}
        />
      ) : (
        <></>
      )}
    </>
  );
};
