import { memo, ReactNode } from 'react';
import { Aside, Container, Header, Main } from './styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '~/theme';

function LayoutMainComponent({
  aside,
  children,
  header,
}: {
  aside?: ReactNode;
  children?: ReactNode;
  header?: ReactNode;
}) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const hasAside = Boolean(aside && isDesktop);
  const hasHeader = Boolean(header);

  return (
    <Container>
      {hasAside ? <Aside>{aside}</Aside> : null}
      {hasHeader ? <Header hasOffset={hasAside}>{header}</Header> : null}
      <Main hasAside={hasAside} hasHeader={hasHeader}>
        {children}
      </Main>
    </Container>
  );
}

export const LayoutMain = memo(LayoutMainComponent);
