import MUICheckbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { theme } from '~/theme';

export const Checkbox = styled(MUICheckbox)({});

export const UncheckedIcon = styled('span')({
  boxShadow: `0 0 0 1px ${theme.palette.shade.grey3}`,
  backgroundColor: theme.palette.backgroundExtension.white,
  borderRadius: '4px',
  width: '20px',
  height: '20px',
});

export const CheckedIcon = styled('span')({
  boxShadow: `0 0 0 1px ${theme.palette.shade.grey1}`,
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  backgroundColor: theme.palette.shade.grey1,
  /* TODO: add proper image from icons */
  backgroundImage:
    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
  content: '""',
});

export const CheckedErrorIcon = styled('span')({
  boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  backgroundColor: theme.palette.error.main,
  /* TODO: add proper image from icons */
  backgroundImage:
    'url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22white%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%0A%20%20%20%20%20%20%20%20%20%20d%3D%22M5.63589%204.2218L11.9999%2010.5858L18.3638%204.2218L19.778%205.63602L13.4141%2012L19.778%2018.3639L18.3638%2019.7781L11.9999%2013.4142L5.63589%2019.7781L4.22168%2018.3639L10.5856%2012L4.22168%205.63602L5.63589%204.2218Z%22%0A%20%20%20%20%20%20%20%20%20%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E")',
  content: '""',
});
