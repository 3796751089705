import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { apiClient } from '~/api/axios/axios-client';
import { queryClient } from '~/api/queryClient';

const getMeInfoService = (): Promise<AxiosResponse> => apiClient.get('/api/v1/me');

const patchMeInfoService = (payload: any): Promise<AxiosResponse> => apiClient.patch('/api/v1/me', payload);

export const useGetMeInfo = () => {
  const { data: meInfo, ...rest } = useQuery({
    queryKey: ['meInfo'],
    queryFn: async () => {
      const response = await getMeInfoService();
      return response.data;
    },
  });
  return { meInfo, ...rest };
};

export const usePatchMeInfo = () => {
  const { mutateAsync: patchMeInfo, ...rest } = useMutation({
    mutationFn: async (requestBody: any) => {
      const response = await patchMeInfoService(requestBody);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meInfo'] });
    },
  });

  return { patchMeInfo, ...rest };
};
