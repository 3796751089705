import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';

export const StyledMuiLink = styled(Link)({
  color: 'inherit',
  target: '_blank',
  textDecoration: 'none',
  px: 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  padding: '0 1.5rem',
  minHeight: 40,
  cursor: 'pointer',
});
