import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom/client';
import KycApp from '~/KycApp';
import { initSnowplowTracker } from '@ev/snowplow-library';

if (import.meta.env.VITE_DATADOG_CLIENT_SECRET) {
  datadogRum.init({
    applicationId: 'e4ea710d-21c2-4f37-8887-7ba428f78cf7',
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_SECRET || '',
    site: 'datadoghq.com',
    service: 'kyc-frontend',
    env: import.meta.env.VITE_DATADOG_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 40,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  });
}

initSnowplowTracker({
  applicationId: 'kyc-ui',
  hasUserAcceptedAgreement: true,
  isProd: window.location.host.includes('engelvoelkers.com'),
  initialTrackPageViewEvent: false,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <KycApp />
  </React.StrictMode>,
);
