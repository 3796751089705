import { Center, Container, Left, Right } from './styles';
import { ReactNode, memo } from 'react';

function HeaderComponent({
  center,
  fullHeight,
  left,
  right,
}: {
  center?: ReactNode;
  fullHeight?: boolean;
  left?: ReactNode;
  right?: ReactNode;
}) {
  return (
    <Container fullHeight={fullHeight}>
      <Left>{left}</Left>
      <Center>{center}</Center>
      <Right>{right}</Right>
    </Container>
  );
}

export const Header = memo(HeaderComponent);
