import { useMemo } from 'react';

export function useCookies() {
  return useMemo(() => {
    return {
      getCookie(cookieName: string) {
        const cookieTag = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(cookieTag) == 0) {
            return c.substring(cookieTag.length, c.length);
          }
        }
        return null;
      },

      setCookie(name: string, value: string, hours: number) {
        const d = new Date();
        d.setTime(d.getTime() + hours * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
      },

      deleteCookie(name: string) {
        document.cookie = name + '=; Max-Age=-99999999;';
      },
    };
  }, []);
}
