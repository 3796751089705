import React, { memo } from 'react';

import Checkbox from '@mui/material/Checkbox';

import { UncheckedIcon, CheckedIcon } from './styles';

function CheckBoxComponent({
  onChangeCheckbox,
  value,
  checked,
}: {
  onChangeCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  checked?: boolean;
}) {
  return (
    <Checkbox
      color="secondary"
      icon={<UncheckedIcon />}
      onChange={onChangeCheckbox}
      checkedIcon={<CheckedIcon />}
      value={value}
      checked={checked}
    />
  );
}

export const CheckBox = memo(CheckBoxComponent);
