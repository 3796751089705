import { styled } from '@mui/material/styles';

export const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  height: `68px`,
  marginInline: `-$8px`,
  cursor: 'pointer',
  '::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    inset: `auto 8px 0`,
    height: '1px',
    backgroundColor: theme.palette.divider,
  },
}));
