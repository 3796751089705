import { ElementType, MouseEvent, ReactNode } from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { theme } from '~/theme';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

interface MobileMenuItemProps {
  icon: ElementType;
  label: string;
  href?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  endIcon?: ReactNode;
}

export const MobileMenuItem = ({ icon, label, href, onClick, endIcon, ...rest }: MobileMenuItemProps) => {
  const { pathname } = useLocation();
  const isActive = href
    ? (pathname.startsWith(href) && href !== '/') || (href === pathname && pathname === '/')
    : false;

  return (
    <ListItem disablePadding dense {...rest}>
      <ListItemButton
        selected={isActive}
        sx={{
          paddingY: 1.5,
          paddingX: 1,
          '&.Mui-selected': {
            color: theme.palette.brand.red,
            backgroundColor: 'white',
          },
          display: 'flex',
          gap: 1.5,
        }}
        to={href}
        component={href ? Link : 'button'}
        onClick={onClick}
      >
        <ListItemIcon>
          <SvgIcon component={icon} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{label}</ListItemText>

        {endIcon && <ListItemIcon>{endIcon}</ListItemIcon>}
      </ListItemButton>
    </ListItem>
  );
};
