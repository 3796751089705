import { styled } from '@mui/system';
import { theme } from '~/theme';

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})<{
  fullHeight?: boolean;
}>(({ fullHeight }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  height: fullHeight ? '100%' : undefined,
}));

export const Center = styled('div')({
  display: 'flex',
  justifySelf: 'center',
  maxWidth: theme.spacing(100),
});

export const Left = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifySelf: 'flex-start',
});

export const Right = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifySelf: 'flex-end',
});
