import React, { ReactNode } from 'react';
import { Box, DialogTitle, IconButton, Stack, SvgIcon } from '@mui/material';

import CrossIcon from '~/components/icons/cross-naked.svg';
import { theme } from '~/theme';

interface SwipeableDrawerContentProps {
  onClose: () => void;
  title?: string;
  children: ReactNode;
}

export const SwipeableDrawerContent = ({ title, children, onClose }: SwipeableDrawerContentProps) => (
  <Box
    sx={{
      position: 'relative',
      padding: 2,
      backgroundColor: theme.palette.backgroundExtension.grey,
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 8,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 37,
        height: 4,
        backgroundColor: 'grey.400',
        borderRadius: 0.5,
      }}
    />
    <Stack display="grid" gridTemplateColumns="24px 1fr 24px">
      <IconButton onClick={onClose} sx={{ marginBottom: title ? 0 : 1 }}>
        <SvgIcon component={CrossIcon} />
      </IconButton>
      {title && <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>}
    </Stack>
    {children}
  </Box>
);
