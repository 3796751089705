import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import QuestionMarkIcon from '~/components/icons/question.svg';
import LinkIcon from '~/components/icons/external-link-icon.svg';
import DowJonesLogo from '~/components/icons/dowjones-logo.svg';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '~/theme';
import { StyledMuiLink } from './styles';
import { useTranslation } from '~/util/i18next';
import { aboutThisToolLink, featureRequestLink, imprintLink, knowledgeBaseLink } from '~/util/consts/links';

export function HelpSection() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            minWidth: 180,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: isDesktop ? 0 : 64,
              left: isDesktop ? 0 : 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem disableGutters={true}>
          <StyledMuiLink href={aboutThisToolLink} target="_blank">
            {t('general.aboutkyc')}
            <LinkIcon />
          </StyledMuiLink>
        </MenuItem>
        <MenuItem disableGutters={true}>
          <StyledMuiLink href={knowledgeBaseLink} target="_blank">
            {t('general.knowledgebase')}
            <LinkIcon />
          </StyledMuiLink>
        </MenuItem>
        <MenuItem disableGutters={true}>
          <StyledMuiLink href={featureRequestLink} target="_blank">
            {t('general.featurerequest')}
            <LinkIcon />
          </StyledMuiLink>
        </MenuItem>
        <MenuItem disableGutters={true}>
          <StyledMuiLink href={imprintLink} target="_blank">
            {t('general.imprint')}
            <LinkIcon />
          </StyledMuiLink>
        </MenuItem>
        <Box width={'100%'} maxHeight={40} textAlign={'center'}>
          <DowJonesLogo />
        </Box>
      </Menu>
    </>
  );
}
