import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExternalIcon from '~/components/icons/external-link-icon.svg';
import { useTranslation } from '~/util/i18next';
import { Link } from 'react-router-dom';

export interface SupportLinkProps {
  link: string;
  translationKey: string;
}

export const SupportLink = ({ link, translationKey }: SupportLinkProps) => {
  const { t } = useTranslation();
  return (
    <ListItemButton to={link} component={Link} target="_blank" rel="noreferrer">
      <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t(translationKey)}</ListItemText>
      <ListItemIcon>
        <ExternalIcon />
      </ListItemIcon>
    </ListItemButton>
  );
};
