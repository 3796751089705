import { styled } from '@mui/material/styles';
import { theme } from '~/theme';
import Typography from '@mui/material/Typography';

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
});

export const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.shade.grey3,
}));
